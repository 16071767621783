.about {
  position: relative;
  background: #4471a1;
  z-index: 28;
  height: 1900px;
}

.about #home_bye {
  user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  width: 100%;
  z-index: 49;
}

.about #about_middle {
  user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  top: 600px;
  left: 0;
  z-index: 0;
  width: 100%;
  max-height: 1500px;
}

.about #about-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 85%;
  margin: auto;
  margin-top: 100px;
}

.about #about-inner .about-column {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 500px;
}

.about #about-inner .about-column:last-child {
  margin-top: -800px;
}

.about #about-inner .about-column .about-item {
  position: relative;
  max-width: 600px;
  z-index: 27;
  word-wrap: break-word;
}

.about #about-inner .about-column .about-item .title {
  user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  color: #fff;
  font-size: .36rem;
  line-height: .5rem;
  font-weight: 200;
  margin-bottom: 10px;
}

.about #about-inner .about-column .about-item .body {
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-box-shadow: 0px 15px 40px 5px #00000040;
          box-shadow: 0px 15px 40px 5px #00000040;
  font-size: .4rem;
  line-height: .55rem;
  border-radius: 20px;
  background: #fff;
  padding: 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-height: 3000px;
  overflow-y: auto;
}

.about #about-inner .about-column .about-item .body:hover {
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-box-shadow: 0px 15px 40px 5px #00000088;
          box-shadow: 0px 15px 40px 5px #00000088;
}

.about #about-inner .about-column .about-item .body .body-title img {
  position: relative;
  top: 10px;
  height: 60px;
  width: auto;
}

.about #about-inner .about-column .about-item .body .title-left {
  margin-top: 20px;
  width: 100%;
  font-size: 1.4rem;
}

.about #about-inner .about-column .about-item .body .body-text {
  padding-top: 0px;
  margin: 20px 0 40px 0;
  width: 100%;
  position: relative;
}

.about #about-inner .about-column .about-item .body .body-text b {
  line-height: .165rem;
}

.about #about-inner .about-column .about-item .body .body-text img {
  position: relative;
  top: 4px;
  height: 18px;
  width: auto;
}

.about #about-inner .about-column .about-item .body .body-text #useContext {
  height: 15px;
}

.about #about-inner .about-column .about-item .body .body-text ul {
  margin-top: 30px;
  margin-bottom: 50px;
  margin-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  list-style: inside;
}

.about #about-inner .about-column .about-item .body .body-text ul li {
  display: list-item;
}

.about #about-inner .about-column .about-item .body #lighthouseVid {
  width: 100%;
  height: auto;
}

.react-rotate {
  -webkit-animation: react-logo-spin infinite 20s linear;
          animation: react-logo-spin infinite 20s linear;
}

@-webkit-keyframes react-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes react-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
