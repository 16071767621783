#home {
  position: relative;
  z-index: 50;
  background-color: #082338;
  width: 100vw;
  height: calc( 100vh + 100px);
  padding-bottom: 150px;
}

#home #homeVidSrc {
  position: absolute;
  z-index: 51;
  display: block;
  overflow-x: hidden;
  width: auto;
  height: 100vh;
  opacity: 0.15;
  left: -100%;
  -webkit-filter: contrast(1.2) brightness(2) saturate(3);
          filter: contrast(1.2) brightness(2) saturate(3);
  -webkit-box-shadow: 0 5px 100px 20px #082338;
          box-shadow: 0 5px 100px 20px #082338;
}

#home .home-text-div {
  z-index: 55;
  font-weight: 500;
  position: relative;
  top: 22%;
  left: 9%;
  color: #fff;
}

#home .home-text-div .home-text-line {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}

#home .home-text-div .home-text-line:first-child {
  gap: 0;
  font-size: 1.28rem;
  line-height: 2.2rem;
  font-weight: 200;
}

#home .home-text-div .home-text-line:nth-child(2) {
  font-size: 1.5rem;
  line-height: 1rem;
}

#home .home-text-div .home-text-line:nth-child(3) {
  font-weight: 300;
  font-size: .32rem;
  line-height: 0.9rem;
  letter-spacing: .4rem;
}

#home .home-text-div .home-text-line:nth-child(4) {
  font-size: .48rem;
  line-height: .3rem;
  letter-spacing: .1rem;
}

#home .home-text-div .home-text-line a {
  position: relative;
  margin: 15px 0;
  cursor: pointer;
}

#home .home-text-div .home-text-line a img {
  height: 35px;
  width: auto;
}

#home .home-text-div .home-text-line a:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

#home .home-text-div .home-text-line span {
  display: block;
  height: 1px;
  width: 30px;
  background: #fff;
  position: relative;
  margin-inline: 10px;
}
