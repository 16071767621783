@supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
  .project-title {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
}

#projects_div {
  position: relative;
  background: #334756;
  z-index: 25;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

#projects_div .spinner {
  -webkit-transform: scale(5);
          transform: scale(5);
  margin-top: 100px;
}

#projects_div #about_bye {
  width: 100%;
  user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 26;
}

#projects_div #dont-ask {
  -webkit-transform: rotate(11deg);
          transform: rotate(11deg);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  top: 8.7vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 30;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80%;
  position: absolute;
  line-height: .29rem;
  color: #fff;
}

#projects_div #dont-ask p {
  margin: 0 5px;
}

#projects_div #dont-ask .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 90%;
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-size: .43rem;
  font-weight: 200;
}

#projects_div #dont-ask .top p {
  color: #E73636;
  font-weight: 500;
}

#projects_div #dont-ask .top p b {
  font-weight: 600;
}

#projects_div #dont-ask .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-item-align: end;
      align-self: flex-end;
  font-size: .51rem;
  font-weight: 400;
}

#projects_div #dont-ask .bottom p {
  color: #22D234;
}

#projects_div #dont-ask .bottom p b {
  font-weight: 600;
}

#projects_div .title {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 100px;
  -ms-flex-item-align: start;
      align-self: flex-start;
  color: #fff;
  font-weight: 200;
  position: relative;
  padding-left: 7%;
  margin-bottom: 50px;
}

#projects_div .projects {
  margin-bottom: 150px;
  z-index: 30;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}

#projects_div .projects .project-div {
  position: relative;
  z-index: 31;
  width: 50%;
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
  height: 28.12148vw;
  margin: 0;
  padding: 0;
  gap: 0;
}

#projects_div .projects .project-div:hover {
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
}

#projects_div .projects .project-div:hover .vid {
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

#projects_div .projects .project-div:hover button {
  opacity: 1;
}

#projects_div .projects .project-div .project-techs {
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  z-index: 33;
  gap: 5px;
  padding: 7px 12px;
}

#projects_div .projects .project-div .project-techs img {
  height: 20px;
  width: auto;
}

#projects_div .projects .project-div .project-title {
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
  position: absolute;
  top: 30%;
  font-size: 0.3rem;
  line-height: 0.5rem;
  padding-inline: 30px 20px;
  font-weight: 200;
  z-index: 33;
  background: #33475677;
  color: #fff;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

#projects_div .projects .project-div .vid {
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
  z-index: 32;
  top: 0;
  position: relative;
  width: 100%;
  height: auto;
  opacity: 0.5;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

#projects_div .projects .project-div button {
  opacity: 0;
  position: absolute;
  z-index: 35;
  width: 100px;
  line-height: 0.4rem;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-weight: 200;
  padding: 5px 10px;
  font-size: 0.35rem;
  background: #4471a1;
  -webkit-box-shadow: 0px 2px 20px 10px #0005;
          box-shadow: 0px 2px 20px 10px #0005;
  left: calc( 50% - 60px);
  top: 60%;
}

#projects_div .projects .project-div button:hover {
  -webkit-box-shadow: 0px 2px 10px 5px #0003;
          box-shadow: 0px 2px 10px 5px #0003;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.modal-project {
  position: relative;
  background: #334756;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  width: 100%;
  left: 0;
  z-index: 110;
}

.modal-project .leave_project {
  position: absolute;
  top: -15px;
  left: -15px;
  -webkit-transform: scale(0.8) translateX(25%);
          transform: scale(0.8) translateX(25%);
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.modal-project .leave_project:hover {
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-transform: scale(1) translateX(10%);
          transform: scale(1) translateX(10%);
}

.modal-project .modal-inner {
  position: absolute;
  width: 100%;
  height: 78vh;
  top: 45px;
  left: 0;
  background: #082032;
  -webkit-box-shadow: inset 0px 4px 47px 27px rgba(0, 0, 0, 0.25);
          box-shadow: inset 0px 4px 47px 27px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}

.modal-project .modal-inner a {
  font-size: .32rem;
  line-height: .32rem;
  color: #fff;
  font-weight: 200;
  background: #FF4C29;
  text-align: center;
  border-radius: 15px;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  width: 80%;
  -webkit-box-shadow: 0px 4px 15px 5px #00000080;
          box-shadow: 0px 4px 15px 5px #00000080;
  padding: 12px 0;
}

.modal-project .modal-inner a:hover {
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-box-shadow: 0px 4px 25px 10px #00000040;
          box-shadow: 0px 4px 25px 10px #00000040;
}

.modal-project .modal-inner button {
  font-size: .32rem;
  line-height: .32rem;
  color: #fff;
  cursor: not-allowed;
  font-weight: 200;
  background: #aaa;
  text-align: center;
  padding: 12px 0;
  border-radius: 15px;
  width: 80%;
  -webkit-box-shadow: 0px 4px 15px 5px #00000080;
          box-shadow: 0px 4px 15px 5px #00000080;
}

.modal-project .modal-inner button:hover {
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-box-shadow: 0px 4px 25px 10px #00000040;
          box-shadow: 0px 4px 25px 10px #00000040;
}

.modal-project .modal-inner .grid {
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
      grid-template-columns: 100%;
  -ms-grid-rows: 20% 30% auto 60px 60px;
      grid-template-rows: 20% 30% auto 60px 60px;
      grid-template-areas: "title" "video" "desc" "github" "website";
  justify-items: center;
}

.modal-project .modal-inner .grid .grid-title {
  margin-top: 15px;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: title;
  justify-self: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
}

.modal-project .modal-inner .grid .grid-title .project-techs {
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 33;
  gap: 10px;
  padding: 5px 30px 0px 30px;
}

.modal-project .modal-inner .grid .grid-title .project-techs img {
  height: 35px;
  width: auto;
}

.modal-project .modal-inner .grid .grid-title .project-title {
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
  font-size: .7rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  line-height: 1rem;
  padding-inline: 20% 30px;
  font-weight: 200;
  z-index: 33;
  background: #33475677;
  color: #fff;
}

.modal-project .modal-inner .grid .grid-desc {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: desc;
  overflow-y: overlay;
  overflow-x: hidden;
}

.modal-project .modal-inner .grid .grid-desc .project-desc {
  color: #fff;
  padding: 10px 30px;
  width: 86%;
  font-size: .36rem;
  line-height: .44rem;
  font-weight: 200;
}

.modal-project .modal-inner .grid .grid-video {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: video;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 15px;
}

.modal-project .modal-inner .grid .grid-video .vid {
  margin: auto;
  width: auto;
  max-width: 95%;
  height: 100%;
  border-radius: 15px;
}

.modal-project .modal-inner .grid .grid-github {
  width: 100%;
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  grid-area: github;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-project .modal-inner .grid .grid-website {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: website;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.ReactModal__Content {
  inset: 0px !important;
}

.ReactModal__Overlay {
  background: #334756 !important;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  z-index: 105;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ReactModal__Overlay--after-open {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.ReactModal__Overlay--before-close {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
