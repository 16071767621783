#editor
    position: absolute
    right: 7.5%
    margin: auto
    bottom: 200px
    z-index: 47
    width: 85%
    height: 250px
    font-size: .3rem
    line-height: .6rem
    font-weight: 300
    color: #fff
    text-align: center
    border-top-left-radius: 15px
    .item-title
        user-drag: none
        user-select: none
    .title
        user-drag: none
        user-select: none
        transition: 0.3s all ease-in-out
        box-shadow: 0px 4px 12px 7px #00000040
        text-align: left
        font-weight: 700
        padding: 5px 25px
        font-size: 0.26rem
        line-height: 0.6rem
        background: #223
        color: #ccc
        border-top-left-radius: 15px
        &:hover
            transition: 0.3s all ease-in-out
            box-shadow: 0px 4px 13px 5px #00000088
    #editor_inside
        transition: 0.3s all ease-in-out
        box-shadow: 0px 4px 12px 7px #00000040
        overflow-y: scroll
        max-height: 250px
        font-size: 0.24rem
        line-height: 0.26rem
        &:hover
            transition: 0.3s all ease-in-out
            box-shadow: 0px 4px 13px 5px #00000088
    #error_inside
        color: #FF4C29
        overflow-y: scroll
        background: #aaa
        border-bottom-left-radius: 15px
        background: #2225
        font-size: 0.18rem
        line-height: 0.15rem