
@supports (backdrop-filter: blur(5px))
    #nav, #menu
        backdrop-filter: blur(5px)
@keyframes showLine 
    0%
        width: 0px
    100%
        width: 75px
@keyframes hideLine 
    0%
        width: 75px
    100%
        width: 0

#mobile_menu
    display: none
    overflow-x: hidden
    #menuToggle
        display: block
        position: fixed
        top: 50px
        right: 40px
        z-index: 1000
        -webkit-user-select: none
        user-select: none
        a
            text-decoration: none
            color: #232323
            transition: color 0.3s ease
        &:hover
            color: tomato
        input
            display: block
            width: 40px
            height: 32px
            position: absolute
            top: -7px
            left: -5px
            cursor: pointer
            opacity: 0
            z-index: 2
            -webkit-touch-callout: none
        span
            display: block
            width: 33px
            height: 4px
            margin-bottom: 5px
            position: relative
            background: #cdcdcd
            border-radius: 3px
            z-index: 1
            transform-origin: 4px 0px
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),opacity 0.55s ease
            &:first-child
                transform-origin: 0% 0%
            &:nth-last-child(2)
                transform-origin: 0% 100%
        input:checked ~ span
                opacity: 1
                transform: rotate(45deg) translate(-2px, -1px)
        input:checked ~ span:nth-last-child(3)
                opacity: 0
                transform: rotate(0deg) scale(0.2, 0.2)
        input:checked ~ span:nth-last-child(2)
                transform: rotate(-45deg) translate(0, -1px)
        input:checked ~ ul
            transform: translate(-30%, 0)
    #menu
        position: absolute
        display: flex
        flex-direction: column
        text-align: right
        width: 100px
        margin: -100px 0 0 -50px
        padding: 50px
        padding-top: 125px
        height: 100vh
        background: #33475685
        list-style-type: none
        -webkit-font-smoothing: antialiased
        transform-origin: 0% 0%
        transform: translate(100%, 0)
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0)
        li
            display: flex
            flex-direction: column
            align-items: flex-end
            padding: 5px 0
            color: #fff
            font-weight: 200
            font-size: 22px
            .line-divide
                height: 1px
                width: 80%
                opacity: 0.4



#nav
    width: 100vw 
    left: 0
    display: flex
    justify-content: flex-end
    position: fixed
    top: 30px
    z-index: 100
    font-size: 20px
    background-color: #33475645
    transition: 0.7s ease-in-out all
    #menu
        padding-right: 20px
        display: flex
        line-height: 75px
        align-items: center
        flex-direction: row
        justify-content: flex-end
        color: #fff
        .a-active
            background-color: #33475685
            .text
                &::after
                    animation: showLine 0.7s ease-in-out forwards
                    display: block
                    position: absolute
                    bottom: 15px
                    height: 1px
                    background: #fff
                    content: ""
                    transition: 0.2s ease-in-out all
        a
            &:hover
                background-color: #334756a5
            li
                display: flex
                align-items: center
                flex-direction: row

                .line-divide
                    height: 45px
                    width: 1px
                    opacity: 0.4
                .text
                    display: flex
                    align-items: flex-end
                    justify-content: center
                    padding: 0 70px
            .text
                &::after
                    animation: hideLine 0.5s ease-in-out forwards
                    display: block
                    position: absolute
                    bottom: 15px
                    height: 1px
                    background: #fff
                    content: ""
                    transition: 0.2s ease-in-out all


    span
        display: flex
        width: 33px
        height: 4px
        margin-bottom: 5px
        position: relative
        background: #cdcdcd
        border-radius: 3px
        z-index: 101
        transform-origin: 4px 0px
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease

@media screen and (max-width: 950px)
    #nav
        display: none
    #mobile_menu
        display: block