.contact {
  position: relative;
  background: #082032;
  z-index: 15;
  height: 1300px;
}

.contact #projects_bye {
  user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}

.contact #abyss_hello {
  position: absolute;
  -webkit-box-shadow: 0px 0px 59px 100px #000;
          box-shadow: 0px 0px 59px 100px #000;
  background: #000;
  bottom: 0;
  width: 100%;
  height: 1px;
}

.contact #abyss_button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #aaa;
  font-size: 50px;
  line-height: 70px;
  font-weight: 200;
  position: absolute;
  bottom: 55px;
  right: 15%;
}

.contact #abyss_button:hover {
  -webkit-animation: move_to_abyss 2.0s ease-in-out forwards;
          animation: move_to_abyss 2.0s ease-in-out forwards;
}

.contact #abyss_button #abyss_arrows {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.contact #old {
  position: absolute;
  left: 20px;
  bottom: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: .29rem;
  color: #fff;
  line-height: .3rem;
  font-weight: 300;
  gap: 15px;
}

.contact #old .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.contact #old .column a {
  cursor: pointer;
}

.contact #old .column a:hover {
  -webkit-animation: move_to_old 1.0s ease-in-out forwards;
          animation: move_to_old 1.0s ease-in-out forwards;
}

.contact #old .column .row:nth-child(2) {
  font-size: .25rem;
  font-weight: 200;
}

.contact #old .column .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
}

.contact #old .column .row a {
  -ms-grid-column-align: center;
      justify-self: center;
  position: relative;
  width: 90%;
  padding: 5px 0;
  top: 5px;
  text-align: center;
  background: #FF4C29;
  -webkit-box-shadow: 0px 4px 25px 10px #00000050;
          box-shadow: 0px 4px 25px 10px #00000050;
  border-radius: 15px;
}

.contact #old .column .row a:hover {
  -webkit-animation: none;
          animation: none;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-box-shadow: 0px 4px 25px 12px #00000080;
          box-shadow: 0px 4px 25px 12px #00000080;
}

.contact .columns {
  width: 95%;
  margin: auto;
  gap: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contact .columns .column {
  position: relative;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contact .columns .column .home-text-line {
  margin-left: 50px;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 55px;
}

.contact .columns .column .home-text-line a img {
  height: 50px;
}

.contact .columns .column:last-child {
  top: 0;
}

.contact .columns .column .item-show {
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  background: #334756;
  -webkit-box-shadow: 0px 15px 40px 5px #00000040;
          box-shadow: 0px 15px 40px 5px #00000040;
}

.contact .columns .column .item-show:hover {
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-box-shadow: 0px 15px 40px 5px #00000088;
          box-shadow: 0px 15px 40px 5px #00000088;
}

.contact .columns .column .contact-item {
  font-size: .42rem;
  line-height: .6rem;
  letter-spacing: 1px;
  border-radius: 10px;
  color: #fff;
  width: 90%;
}

.contact .columns .column .contact-item .text {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 20px 20px;
}

.contact .columns .column .contact-item .text b {
  font-size: 0.65rem;
  color: #FF4C29;
}

.contact .columns .column .contact-item .text img {
  position: relative;
  top: 4px;
  height: 18px;
  width: auto;
}

.contact .columns .column .contact-item form {
  padding: 15px 20px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contact .columns .column .contact-item form .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
  -ms-grid-rows: 20% auto 20%;
      grid-template-rows: 20% auto 20%;
      grid-template-areas: "name email" "message message" "button button";
}

.contact .columns .column .contact-item form .grid .title {
  text-align: left;
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.contact .columns .column .contact-item form .grid input {
  width: 90%;
  font-size: 14px;
  color: #000;
  padding: 0 5px;
  margin-top: 5px;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  background: #FFFFFF;
  -webkit-box-shadow: inset 0px 0px 9px 3px #00000040;
          box-shadow: inset 0px 0px 9px 3px #00000040;
  border-radius: 5px;
}

.contact .columns .column .contact-item form .grid input:focus {
  outline: solid #FF4C29;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}

.contact .columns .column .contact-item form .grid .grid-name {
  width: 100%;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: name;
}

.contact .columns .column .contact-item form .grid .grid-email {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: email;
}

.contact .columns .column .contact-item form .grid .grid-email .title {
  margin-left: 6%;
}

.contact .columns .column .contact-item form .grid .grid-message {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: message;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.contact .columns .column .contact-item form .grid .grid-message .title {
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.contact .columns .column .contact-item form .grid .grid-message textarea {
  width: calc( 100% - 40px);
  word-wrap: break-word;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding: 10px 20px;
  margin-top: 5px;
  background: #FFFFFF;
  -webkit-box-shadow: inset 0px 0px 14px 6px #00000040;
          box-shadow: inset 0px 0px 14px 6px #00000040;
  border-radius: 5px;
  height: 130px;
}

.contact .columns .column .contact-item form .grid .grid-message textarea:focus {
  outline: solid #FF4C29;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}

.contact .columns .column .contact-item form .grid .form_bottom {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: button;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contact .columns .column .contact-item form .grid .form_bottom button {
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 50%;
  margin: 20px 0 10px 0;
  text-align: center;
  background: #FF4C29;
  position: relative;
  border-radius: 5px;
  -webkit-box-shadow: 0px 4px 25px 10px #00000040;
          box-shadow: 0px 4px 25px 10px #00000040;
  font-style: normal;
  font-weight: 300;
  font-size: 0.42rem;
  line-height: 0.82rem;
}

.contact .columns .column .contact-item form .grid .form_bottom button:hover {
  -webkit-box-shadow: 0px 4px 25px 10px #00000070;
          box-shadow: 0px 4px 25px 10px #00000070;
}

@-webkit-keyframes move_to_abyss {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
  }
}

@keyframes move_to_abyss {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
  }
}

@-webkit-keyframes move_to_old {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-30%);
            transform: translateX(-30%);
  }
}

@keyframes move_to_old {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-30%);
            transform: translateX(-30%);
  }
}
