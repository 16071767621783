@supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
  #nav, #menu {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
}

@-webkit-keyframes showLine {
  0% {
    width: 0px;
  }
  100% {
    width: 75px;
  }
}

@keyframes showLine {
  0% {
    width: 0px;
  }
  100% {
    width: 75px;
  }
}

@-webkit-keyframes hideLine {
  0% {
    width: 75px;
  }
  100% {
    width: 0;
  }
}

@keyframes hideLine {
  0% {
    width: 75px;
  }
  100% {
    width: 0;
  }
}

#mobile_menu {
  display: none;
  overflow-x: hidden;
}

#mobile_menu #menuToggle {
  display: block;
  position: fixed;
  top: 50px;
  right: 40px;
  z-index: 1000;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}

#mobile_menu #menuToggle a {
  text-decoration: none;
  color: #232323;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

#mobile_menu #menuToggle:hover {
  color: tomato;
}

#mobile_menu #menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#mobile_menu #menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  -webkit-transform-origin: 4px 0px;
          transform-origin: 4px 0px;
  -webkit-transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#mobile_menu #menuToggle span:first-child {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

#mobile_menu #menuToggle span:nth-last-child(2) {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}

#mobile_menu #menuToggle input:checked ~ span {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
}

#mobile_menu #menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
}

#mobile_menu #menuToggle input:checked ~ span:nth-last-child(2) {
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}

#mobile_menu #menuToggle input:checked ~ ul {
  -webkit-transform: translate(-30%, 0);
          transform: translate(-30%, 0);
}

#mobile_menu #menu {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: right;
  width: 100px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  height: 100vh;
  background: #33475685;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#mobile_menu #menu li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 5px 0;
  color: #fff;
  font-weight: 200;
  font-size: 22px;
}

#mobile_menu #menu li .line-divide {
  height: 1px;
  width: 80%;
  opacity: 0.4;
}

#nav {
  width: 100vw;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: fixed;
  top: 30px;
  z-index: 100;
  font-size: 20px;
  background-color: #33475645;
  -webkit-transition: 0.7s ease-in-out all;
  transition: 0.7s ease-in-out all;
}

#nav #menu {
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 75px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  color: #fff;
}

#nav #menu .a-active {
  background-color: #33475685;
}

#nav #menu .a-active .text::after {
  -webkit-animation: showLine 0.7s ease-in-out forwards;
          animation: showLine 0.7s ease-in-out forwards;
  display: block;
  position: absolute;
  bottom: 15px;
  height: 1px;
  background: #fff;
  content: "";
  -webkit-transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
}

#nav #menu a:hover {
  background-color: #334756a5;
}

#nav #menu a li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

#nav #menu a li .line-divide {
  height: 45px;
  width: 1px;
  opacity: 0.4;
}

#nav #menu a li .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 70px;
}

#nav #menu a .text::after {
  -webkit-animation: hideLine 0.5s ease-in-out forwards;
          animation: hideLine 0.5s ease-in-out forwards;
  display: block;
  position: absolute;
  bottom: 15px;
  height: 1px;
  background: #fff;
  content: "";
  -webkit-transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
}

#nav span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 101;
  -webkit-transform-origin: 4px 0px;
          transform-origin: 4px 0px;
  -webkit-transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

@media screen and (max-width: 950px) {
  #nav {
    display: none;
  }
  #mobile_menu {
    display: block;
  }
}
