@import "colors"

#home
    position: relative
    z-index: 50
    background-color: $homeColor
    width: 100vw
    height: calc( 100vh + 100px )
    #homeVidSrc
        position: absolute
        z-index: 51
        display: block
        overflow-x: hidden
        width: auto
        height: 100vh
        opacity: 0.15
        left: -100%
        filter: contrast(1.2) brightness(2) saturate(3.0) 
        box-shadow: 0 5px 100px 20px $homeColor

    .home-text-div
        z-index: 55
        font-weight: 500
        position: relative
        top: 22%
        left: 9%
        color: #fff
        .home-text-line
            user-select: none
            display: flex
            align-items: center
            gap: 30px
            &:first-child
                gap: 0
                font-size: 1.28rem
                line-height: 2.2rem
                font-weight: 200
            &:nth-child(2)
                font-size: 1.5rem
                line-height: 1rem
            &:nth-child(3)
                font-weight: 300
                font-size: .32rem
                line-height: 0.9rem
                letter-spacing: .4rem
            &:nth-child(4)
                font-size: .48rem
                line-height: .3rem
                letter-spacing: .1rem
            a
                position: relative
                pointer-events: 
                margin: 15px 0
                cursor: pointer
                img
                    height: 35px
                    width: auto
                &:hover
                    transform: scale(1.5)
            span
                display: block
                height: 1px
                width: 30px
                background: #fff
                position: relative
                margin-inline: 10px 

    padding-bottom: 150px

