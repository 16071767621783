
.oldcontact{
    font-size: 72px;
    width: 100vw;
    height: 100vh;
    transition: 0.7s all ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.oldcontact-title{
    text-align: left;
    position: relative;
    transition: 0.4s all ease-in-out;
    opacity: 1;
    top:0;
    width: 60%;
    font-size: 92px;
    padding-top: 70px;
    cursor: default;
    display: flex;
    justify-content: flex-start;
    line-height: 110px;
    font-size: 82px;
}

.oldthird-line{
    font-size: 32px !important;
    line-height: 40px !important;
    padding-bottom: 20px !important;
}

.oldcontact-panel{
    position: relative;
    background-color: #fff;
    border-radius: 20px;
    width: 60vw;
    height: max-content;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 40px;
    padding:40px;
    box-shadow: 0 0 35px 5px #0002;
    transition: 0.2s all ease-in-out;
}
.oldcontact-panel:hover{
    transition: 0.2s all ease-in-out;
    box-shadow: 0 0 35px 5px #0003;
}
.oldone-panel{
    width: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-top: 25px;
}
.oldone-panel img{
    width: 50px;
    height: 50px;
}
.oldone-panel-element{
    margin-top: 15px;
    font-size: 26px;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 0 0 5px 5px #0002;
    padding:10px 40px;
    padding-left: 20px;
    transition: 0.2s all ease-in-out;
    width: max-content;
}
.oldone-panel-element:hover{
    transition: 0.2s all ease-in-out;
    box-shadow: 0 0 10px 10px #0001;

}

@media only screen and (max-width:500px) {
    
}

@media only screen and (max-width: 1500px) {
    .oldthird-line{font-size: 28px !important;line-height: 34px !important;}
}
@media only screen and (max-width: 1000px) {
    .oldthird-line{font-size: 24px !important;line-height: 30px !important;}
}
@media only screen and (max-width: 750px) {
    .oldthird-line{font-size: 14px !important;line-height: 18px !important; padding-bottom: 10px !important;}
    .oldcontact-title{font-size: 32px;line-height: 31px;padding-left: 40px;padding-top: 120px;}
    .oldcontact{align-items: unset;}
    .oldcontact-panel{width: 80vw;padding: 20px;left:5%;}
    .oldone-panel img{width: 30px;height: 30px;}
    .oldone-panel-title{margin-left:5px;font-size: 18px;}
}