
#abyssBody{
    z-index: 1998;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    perspective: 700px;
    perspective-origin: 50% 35%;
    transition: all 1.3s ease-in-out;
    cursor: grab;
}
#abyssThanks{
    color: #000;
    position: absolute;
    transition: all 1.3s ease-in-out;
    top: 15%;
    font-size: 20px;
    opacity: 0;
    letter-spacing: 5px;
    user-select: none;
}
@media screen and (min-width: 500px) {
    #abyssThanks{font-size: 48px;right: 20%;letter-spacing: 15px;}
}
#abyssScene{
    transition: all 1.3s ease-in-out;
    position: relative;
    top: 0%;
    padding-bottom: 20vh;
    transform-style: preserve-3d;
    animation: scene-rotate 11.9s infinite linear;
}
.abyss-circle{
    transition: all 0.7s ease-in-out;
    z-index: 5;
    position: absolute;
    left: calc( 50% - 35px );
    top: calc( 50% - 36px );
    width: 70px;
    height: 72px;
    border-radius: 50%;
    border: 1px solid #fff;
    transform-style: preserve-3d;
}
#abyssCircle{
    cursor: grabbing;
}
#abyssCircle:hover ~ #ball{
    animation-play-state: paused;
}
.hover-after:hover .abyss-circle{
    border-color: #f00 !important;
}
.flat2{
    transition: all 1.3s ease-in-out;
    left: calc( 50% - 500px);
    top: calc( 50% - 500px);
    width: 1000px;
    height: 1000px;
    position: absolute;
    border-radius: 20%;
    opacity: 0.5;
    background-image: radial-gradient(#fff4 0%,#000f 70%),repeating-conic-gradient(from 45deg, #111 0deg 90deg, #222 90deg 180deg);
    background-size: 100%, 10% 10%;
    transform: rotateX(90deg) translateZ(-100px);
}
#abyssCircle{
    transition: all 1.3s ease-in-out;
    transform-style: preserve-3d;
    position: absolute;
}
.abyss-circle{
    transition: all 1.3s ease-in-out;
    animation: 7s circle-rotate linear infinite;
}

.abyss-circle:nth-child(3){
    animation: 3s circle-rotate2 linear infinite;
}

.abyss-circle:nth-child(2){
    animation: 3s circle-rotate3 linear infinite;
}


#ball{
    transition: all 1.3s ease-in-out;
    z-index: -1;
    position: absolute;
    width: 30px;
    height: 30px;
    transform-style: preserve-3d;
    animation: move-ball 1.7s ease-in-out infinite;
}
#ballIn{
    transition: all 1.3s ease-in-out;
    animation: ball-rotate 11.9s infinite linear;
    position: absolute;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform-style: preserve-3d;
    background: radial-gradient(circle at 30% 30%, #ffff, #555f 60%, #222f 90%);

}
#ball .cube-left, #ball .cube-right, #ball .cube-front, #ball .cube-back,#ball .cube-top,#ball .cube-bottom {
    animation: box-disapear 11.9s linear infinite;
}

#ball .cube-left, #ball .cube-right, #ball .cube-front, #ball .cube-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fffc;
    -webkit-box-shadow: 0 0 .5em #fff5 inset;
            box-shadow: 0 0 .5em #fff5 inset;
  }
  
  #ball .cube-front {
    -webkit-transform: translateZ(15px);
            transform: translateZ(15px);
  }
  
  #ball .cube-right {
    -webkit-transform: rotateY(90deg) translateZ(15px);
            transform: rotateY(90deg) translateZ(15px);
  }
  
  #ball .cube-back {
    -webkit-transform: rotateY(180deg) translateZ(15px);
            transform: rotateY(180deg) translateZ(15px);
  }
  
  #ball .cube-left {
    -webkit-transform: rotateY(270deg) translateZ(15px);
            transform: rotateY(270deg) translateZ(15px);
  }
  
  #ball .cube-top {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff5;
    -webkit-transform: translateY(-50%) rotateX(90deg);
            transform: translateY(-50%) rotateX(90deg);
  }
  
  #ball .cube-bottom {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff5;
    opacity: .4;
    -webkit-transform: translateY(50%) rotateX(270deg);
            transform: translateY(50%) rotateX(270deg);
    -webkit-box-shadow: 0 0 3em #000;
            box-shadow: 0 0 3em #000;
  }
#waves{
    transition: all 1.3s ease-in-out;
    position: absolute;
    transform-style: preserve-3d;
    left: 50%;
    top: 50%;
}
.abyss-wave{
    transition: all 1.3s ease-in-out;
    transform-style: preserve-3d;
    position: absolute;
    border: 1px solid #fff2;
    width: 70px;
    left: calc(50% - 35px);
    top: calc(50% - 35px);
    opacity: 0;
    height: 70px;
    border-radius: 50%;
}
.abyss-wave:nth-child(1){
    animation: wave 5.4s infinite 0.3s ease-out;
}
.abyss-wave:nth-child(2){
    animation: wave2 5.4s infinite 0.9s ease-out;
}
.abyss-wave:nth-child(3){
    animation: wave3 5.4s infinite 1.2s ease-out;
}

#floorWaves{
    transition: all 1.3s ease-in-out;
    position: absolute;
    left: calc( 50% - 500px);
    top: calc( 50% - 300px);
    width: 1000px;
    height: 1000px;
    transform-style: preserve-3d;
    transform: rotateX(90deg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.abyss-wave-floor{
    transition: all 1.3s ease-in-out;
    position: absolute;
    background-color: #000;
    opacity: 0;
    border: solid 1px #777;
    border-radius: 100%;
}
.abyss-wave-floor:nth-child(1){
    animation: wave-floor 2.4s infinite 0.3s ease-out;
}
.abyss-wave-floor:nth-child(2){
    animation: wave-floor 2.4s infinite 0.9s ease-out;
}
.abyss-wave-floor:nth-child(3){
    animation: wave-floor 2.4s infinite 1.2s ease-out;
}
  
@keyframes wave-floor{
    0% {width: 65px;height: 67px;opacity: 1;transform: rotate(0);}
    20% {opacity:0.4;}
    60% {opacity:0.05;}
    100% {width: 1000px;height: 1070px;opacity: 0;transform: rotate(-980deg);}
}

@keyframes wave{
    0% {opacity: 1;transform: rotateX(0) scale(1) translate(-33px);}
    20% {opacity:0.4;}
    60% {opacity:0.05;}
    100% {width: 1000px;height: 1070px;opacity: 0;transform: rotateX(1000deg) scale(10);}
}  
@keyframes wave2{
    0% {opacity: 1;transform: rotateY(0) scale(1) translate(-33px);}
    20% {opacity:0.4;}
    60% {opacity:0.05;}
    100% {width: 1000px;height: 1070px;opacity: 0;transform: rotateY(1000deg) scale(10);}
}  
@keyframes wave3{
    0% {opacity: 1;transform: rotateX(0) rotateY(0deg) scale(1) translate(-33px);}
    20% {opacity:0.4;}
    60% {opacity:0.05;}
    100% {width: 1000px;height: 1070px;opacity: 0;transform: rotateX(1000deg) rotateY(1000deg) scale(10);}
}

@keyframes move-ball {
    0% {left: -50vw;transform: scaleZ(100%) scale(100%);}
    30% {border-radius: 10%;transform: scaleZ(30%) scale(30%);}
    50% {left: 50vw;transform: scaleZ(100%) scale(100%);border-radius: 100%;}
    70% {border-radius: 20%;transform: scaleZ(30%) scale(30%);}
    100% {left: -50vw;transform: scaleZ(100%) scale(100%);}
}


@keyframes ball-rotate {
    0% {transform: rotateY(0) scale(50%);}
    40% {transform: rotateY(-216deg) scale(100%);}
    50% {transform: rotateY(-180deg) scale(200%);}
    90% {transform: rotateY(-324deg) scale(250%);}
    91% {transform: rotateY(-327.6deg) scale(100%);}
    100% {transform: rotateY(-360deg) scale(50%);}
}
@keyframes box-disapear {
    0% {opacity: 100%;}
    40% {opacity: 90%;}
    50% {opacity: 0%;}
    90% {opacity: 0%;}
    100% {opacity: 100%;}
}

@keyframes scene-rotate {
    from {transform: rotateY(0);}
    to {transform: rotateY(360deg);}
}
@keyframes circle-rotate {
    from {transform: rotateX(0) rotateY(360deg);}
    to {transform: rotateX(360deg) rotateY(0);}
}
@keyframes circle-rotate2 {
    from {transform: rotateZ(0) rotateX(80deg);}
    to {transform: rotateZ(360deg) rotateX(80deg);}
}
@keyframes circle-rotate3 {
    from {transform: rotateZ(0) rotateY(80deg);}
    to {transform: rotateZ(360deg) rotateY(80deg);}
}