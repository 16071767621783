@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

textarea, button, a, input, ul, li,select{
  all: unset;
  scroll-behavior: smooth;
  transition: 0.2s all ease-in-out;
}
#root{
  transition: 0.5s all ease-in-out;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}
button, a, select{
  cursor: pointer;
}
html{
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
  font-size: 35px;
  line-height: 35px;
}
body {
  position: relative;
  display: flex;
  background-color: #334756 !important;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow-y: overlay;
  font-family: 'Poppins',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*::-webkit-scrollbar{
  height: 5px;
  width: 5px;
}
*::-webkit-scrollbar-thumb{
  background-color: #FF4C29ff;
  border-radius: 50px;
}
.__react_component_tooltip{
  line-height: 20px;
}