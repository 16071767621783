
.oldwelcome{
    font-size: 72px;
    width: 67vw;
    height: 100vh;
    transition: 0.7s all ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    left:12vw;
}
.oldtitle{
    text-align: left;
    position: relative;
    transition: 0.4s all ease-in-out;
    opacity: 0;
    font-size: 140px;
    animation: welcomeTitle 1.4s 0.6s ease-in-out forwards;
    cursor: default;
    display: flex;
    flex-direction: column;
    line-height: 145px;
}
.oldtitle-welcome{
    padding-top: 10%;
    font-weight: 500;
    line-height: 260px;
    font-size: 250px;
    opacity: 0;
    position: relative;
    animation: welcomeTitle 1.4s 0s ease-in-out forwards;
}
.oldtitle-desc a img{
    width: 35px;
    height: 35px;
    cursor: pointer;
}
.oldtitle-desc a{
    width: 45px;
    padding-left: 20px;
    height: 45px;
    transition: 0.2s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.oldtitle-desc a:hover{
    transform:  scale(1.7);
    transition: 0.2s all ease-in-out;
}
.oldtechs{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    opacity: 0;
    position: relative;
    
    cursor:default;
    animation: welcomeFromLeft 1.0s 1.8s ease-in-out forwards;
}
.oldtechs+.oldtechs{
    right: -80px;
    animation: welcomeFromRight 1.0s 2.1s ease-in-out forwards;}
.oldtitle-desc{
    position: relative;
    font-size: 52px;
    opacity: 0;
    animation: welcomeTitle 1.0s 1s ease-in-out forwards;
    cursor: default;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    cursor:default;
}
.oldtechs-title{
    display: flex;
    position: relative;
    flex-direction: row;
    font-size:54px;
    align-items: center;
    white-space: nowrap;
    font-weight: 500;
    opacity: 1;
    cursor:default;
}
.oldtechs-imgs img{width: 80px; height: 80px;margin: 5px;transition: 0.2s all ease-in-out;}
.oldtechs-imgs img:hover{transform: scale(2.0);transition: 0.2s all ease-in-out;}
.oldtechs-imgs{
    margin-left: 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    font-size: 22px;
    opacity:1;
}
.oldlines{
    flex-direction: column;
    line-height: 28px;
}
.oldtechs-line{
    padding: 12px 0;
    font-size: 22px;
    text-align: center;
    width: 100%;
}
@keyframes welcomeTitle {
    0% {top:-80px;opacity: 0;}
    100% {top:0px;opacity: 1;}
}
@keyframes welcomeFromLeft {
    0% {left:-80px;opacity: 0;}
    100% {left:0px;opacity: 1;}
}
@keyframes welcomeFromRight {
    0% {right:-80px;opacity: 0;}
    100% {right:0px;opacity: 1;}
}

@media only screen and (max-width: 1500px) {
    .oldwelcome{font-size: 52px;width: 70vw;}
    .oldtitle-welcome{font-size: 242px;padding-top: 13%;line-height: 250px;}
    .oldtitle{font-size: 142px;line-height: 144px;}
    .oldtitle-desc{line-height: 44px;font-size: 50px;padding-bottom: 80px;}
    .oldtechs-title{font-size: 50px;}
    .oldtechs-imgs{margin-left:40px;}
    .oldtechs-line{font-size: 16px;padding-top: 5px;line-height: 24px;}
    .oldtechs-imgs img{width: 50px;height: 50px;}
}
@media only screen and (max-width: 1000px) {
    .oldwelcome{font-size: 52px;width: 70vw;}
    .oldtitle-welcome{font-size: 182px;padding-top: 20%;line-height: 190px;}
    .oldtitle{font-size: 105px;line-height:110px;}
    .oldtitle-desc{line-height: 48px;font-size: 40px;padding-bottom: 70px;}
    .oldtechs-title{font-size: 38px;}
    .oldtechs-imgs{margin-left:30px;}
    .oldtechs-line{font-size: 14px;padding-top: 4px;padding-bottom: 10px; line-height: 22px;}
    .oldtechs-imgs img{width: 40px;height: 40px;}
}
@media only screen and (max-width: 750px) {
    .oldwelcome{font-size: 52px;width: 90vw;left: 7vw;justify-content: flex-start;}
    .oldtitle-welcome{font-size: 162px;padding-top: 80px;line-height: 170px;}
    .oldtitle{font-size: 50px;line-height:52px;}
    .oldtitle-desc{line-height: 20px;font-size: 16px;padding-bottom: 30px;}
    .oldtechs{flex-direction: column;}
    .oldtechs-title{font-size: 28px;}
    .oldtechs-imgs{margin-left:10px;}
    .oldtechs-line{font-size: 12px;padding-top: 2px;padding-bottom: 5px; line-height: 16px;}
    .oldtechs-imgs img{width: 30px;height: 30px;}
}
