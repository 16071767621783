@import 'colors'

.contact
    position: relative
    background: $contactColor
    z-index: 15
    height: 1300px
    #projects_bye
        user-drag: none
        user-select: none
        width: 100%
    #abyss_hello
        position: absolute
        box-shadow: 0px 0px 59px 100px #000
        background: #000
        bottom: 0
        width: 100%
        height: 1px
    #abyss_button
        user-select: none
        cursor: pointer
        text-align: center
        display: flex
        flex-direction: column
        color: #aaa
        font-size: 50px
        line-height: 70px
        font-weight: 200
        position: absolute
        bottom: 55px
        right: 15%
        &:hover
            animation: move_to_abyss 2.0s ease-in-out forwards 
            
        #abyss_arrows
            transform: rotate(270deg)
    #old
        position: absolute
        left: 20px
        bottom: 15%
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        font-size: .29rem
        color: #fff
        line-height: .3rem
        font-weight: 300
        gap: 15px

        .column
            display: flex
            flex-direction: column
            gap: 3px
            user-select: none
            a
                cursor: pointer
                &:hover
                    animation: move_to_old 1.0s ease-in-out forwards 
            .row:nth-child(2)
                font-size: .25rem
                font-weight: 200
            .row
                display: flex
                position: relative
                width: 100%
                a
                    justify-self: center
                    position: relative
                    width: 90%
                    padding: 5px 0
                    top: 5px
                    text-align: center
                    background: $orange
                    box-shadow: 0px 4px 25px 10px #00000050
                    border-radius: 15px
                    &:hover
                        animation: none
                        transform: scale(1.1)
                        box-shadow: 0px 4px 25px 12px #00000080
                    



    .columns
        width: 95%
        margin: auto
        gap: 100px
        display: flex
        flex-direction: column
        .column
            position: relative
            top: 0
            width: 100%
            display: flex
            flex-direction: column
            align-items: center
            .home-text-line
                margin-left: 50px
                margin-top: 20px
                display: flex
                gap: 55px
                a img
                    height: 50px
            &:last-child
                top: 0

            .item-show
                transition: 0.3s all ease-in-out
                background: $projectsColor
                box-shadow: 0px 15px 40px 5px #00000040

                &:hover
                    transition: 0.3s all ease-in-out
                    box-shadow: 0px 15px 40px 5px #00000088

            .contact-item
                font-size: .42rem
                line-height: .6rem
                letter-spacing: 1px
                border-radius: 10px
                color: #fff
                width: 90%
                .text
                    user-select: none
                    padding: 20px 20px
                    b
                        font-size: 0.65rem
                        color: $orange
                    img
                        position: relative
                        top: 4px
                        height: 18px
                        width: auto
                form
                    padding: 15px 20px
                    position: relative
                    display: flex
                    flex-direction: column
                    .grid
                        display: grid
                        grid-template-columns: 50% 50%
                        grid-template-rows: 20% auto 20%
                        grid-template-areas: "name email" "message message" "button button"
                        .title
                            text-align: left
                            align-self: flex-start
                            font-size: 14px
                            line-height: 17px
                            font-weight: 300
                            user-select: none
                        input
                            width: 90%
                            font-size: 14px
                            color: #000
                            padding: 0 5px
                            margin-top: 5px
                            transition: 0.2s all ease-in-out
                            background: #FFFFFF
                            box-shadow: inset 0px 0px 9px 3px #00000040
                            border-radius: 5px
                            &:focus
                                outline: solid $orange
                                transition: 0.2s all ease-in-out
                        .grid-name
                            width: 100%
                            grid-area: name
                        .grid-email
                            width: 100%
                            display: flex
                            flex-direction: column
                            align-items: flex-end
                            grid-area: email
                            .title
                                margin-left: 6%
                        .grid-message
                            grid-area: message
                            width: 100%
                            display: flex
                            flex-direction: column
                            align-items: center
                            justify-content: center
                            .title
                                width: 100%
                                text-align: left
                                font-size: 14px
                                line-height: 17px
                                font-weight: 300
                                user-select: none
                            textarea
                                width: calc( 100% - 40px )
                                word-wrap: break-word
                                font-size: 14px
                                line-height: 20px
                                color: #000
                                padding: 10px 20px
                                margin-top: 5px
                                background: #FFFFFF
                                box-shadow: inset 0px 0px 14px 6px #00000040
                                border-radius: 5px
                                height: 130px
                                &:focus
                                    outline: solid $orange
                                    transition: 0.2s all ease-in-out
                        .form_bottom
                            grid-area: button
                            user-select: none
                            display: flex
                            justify-content: space-between
                            align-items: center
                            button
                                align-self: flex-end
                                width: 50%
                                margin: 20px 0 10px 0
                                text-align: center
                                background: $orange
                                position: relative
                                border-radius: 5px
                                box-shadow: 0px 4px 25px 10px #00000040
                                font-style: normal
                                font-weight: 300
                                font-size: 0.42rem
                                line-height: 0.82rem
                                &:hover
                                    box-shadow: 0px 4px 25px 10px #00000070
                                    



@keyframes move_to_abyss 
    0% 
        transform: translateY(0%)
    100% 
        transform: translateY(30%)

@keyframes move_to_old 
    0% 
        transform: translateX(0%)
    100% 
        transform: translateX(-30%)
