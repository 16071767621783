
.oldproject-page{
  height: 100vh;
}
.oldprojects-title{
    font-size: 78px;
    padding-left: 120px;
}
.oldcircle-buttons{
    padding-top: 3px;
    padding-bottom: 3px;
    display: none;
    justify-content: space-evenly;
    transition: 0.2s ease-in-out all;
}
.oldscroll-button{
    transition: 0.2s ease-in-out all;
    transform: scale(0.7);
}
.oldscroll-button:hover{
    filter: invert(1);
    cursor: pointer;
    transition: 0.2s ease-in-out all;
}
.oldprojects{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 80%;
    padding: 20px;
    padding-top: 30px;
  }
  .oldproject{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
  }
  .oldproject-inside{
    background-color: #fff;
    position: relative;
    width: 300px;
    min-width: 300px;
    height: 160px;
    min-height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 0 7px 1px #0007;
    transition: 0.2s all ease-in-out;
  }
  
  .oldproject-inside:hover{
    box-shadow: 0 0 12px 4px #0007;
    transition: 0.2s all ease-in-out;
    background-color: #eee;
    
  }

  .oldproject-inside:hover .oldproject-image{
    filter: brightness(0.4);
    transition: 0.3s all ease-in-out;
  }
  .oldproject-inside:hover .oldproject-hover-info{
    bottom: 20px;
    left:20px;
    opacity: 1;
    transition: 0.3s all ease-in-out;
  }
  .oldproject-inside:hover .oldproject-hover-button,.oldproject-inside:hover .oldproject-hover-button-text{
    opacity: 1;
    transition: 0.3s all ease-in-out;
  }
  .oldproject-hover-info{
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    bottom: -10px;
    left:20px;
    opacity: 0;
    transition: 0.3s all ease-in-out;
    color: #fff;
  }
  .oldproject-hover-button{
    position: absolute;
    display: flex;
    justify-content: center;
    top:15%;
    opacity: 0;
    color:#fff;
    border: 2px solid #fff;
    border-radius: 15px;
    padding: 10px 20px;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
  }
  .oldproject-hover-button-text{
    position: absolute;
    display: flex;
    justify-content: center;
    top:15%;
    opacity: 0;
    padding: 10px 20px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
  }
  .oldproject-hover-button:hover{
    color:#000;
    background-color: #fff;
  }
  
  .oldproject-image{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
    height: 100%;
    font-size: 21px;
    border-radius: 7px;
    transition: 0.3s all ease-in-out;
    border-radius: 20px;
  }
  
  .oldproject-title{
    position: absolute;
    padding: 10px;
    color: #fff;
    z-index: 2;
  }
  .oldproject-image img{
    z-index: 1;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 100%;
    filter: blur(1px) brightness(0.8);
    
  }
  
  .oldproject svg{
    width: 95%;
    height: 1px;
  }
  
  .oldproject-technologies{
    margin-top: 7px;
    width: 95%;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  
  .oldproject-technologies-element{
    filter:invert(1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    height: 20px;
    width: 20px;
  }
  

  
.oldproject:hover:after{
  -webkit-justify-content: center;
  justify-content: center;
  background: #222;
  border-radius: 18px;
  color: #fff;
  content: attr(title);
  padding: 12px 16px;
  font-size: 16px;
  bottom: 100%;
  top:unset;
  position: absolute;
  z-index: 7;
  opacity: 0.9;
  transition: 0.2s all ease-in-out;
}
.oldproject:hover:before{
  border: solid;
  border-color: #444 transparent;
  border-width: 12px 6px 0 6px;
  transition: 0.2s all ease-in-out;
  content: "";
  opacity: 0.0;
  position: absolute;
}
  @media only screen and (max-width: 1000px){
      .oldprojects{width:100%;
        height: 480px;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column; 
        overflow-x: hidden;
        padding:0;}
        .oldproject{width: 100vw;padding: 20px 0;}
    .oldprojects-title{font-size: 28px;padding-left: 40px;padding-top: 30px;}
    .oldcircle-buttons{display: flex;}
    .oldproject:hover:after{bottom:unset;top:92%;
      font-size: 12px;}
  }
