
@media screen and (max-width: 305px)
    #projects_div #dont-ask
        top: 50px
    html
        zoom: 0.5
@media screen and (min-width: 500px)
    html
        font-size: 50px
        line-height: 50px
    .about
        height: 2250px
        #about-inner
            .about-column
                gap: 650px
                &:last-child
                    margin-top: -1030px
                .about-item
                    & + .about-item
                        margin-top: -200px
                    .body
                        .body-title img
                            height: 100px
                        .body-text
                            img
                                height: 25px
                            #useContext
                                height: 19px
    #projects_div .projects .project-div button
        font-size: .24rem
    #editor #editor_inside
        font-size: .28rem
        line-height: .28rem
    .contact
        height: 1500px
        .columns .column 
            .contact-item form .grid .form_bottom button
                border-radius: 10px
                font-size: .3rem
                line-height: .7rem
            .home-text-line
                margin-left: 50px
                margin-top: 20px
                a img
                    height: 50px
@media screen and (min-width: 700px)
    #projects_div .projects .project-div button
        padding: 5px 20px
        width: 125px
        font-size: .28rem
        left: calc( 50% - 82.5px )
    .contact
        .columns .column 
            .home-text-line a img
                height: 75px
    .about
        height: 2500px
        #about-inner
            .about-column
                gap: 750px
                &:last-child
                    margin-top: -1130px
    #home #homeVidSrc
        left: 0
        height: 100vh
        width: auto
    html
        font-size: 60px
        line-height: 60px
    .contact
        height: 1700px
        .columns .column
            .contact-item form
                padding: 30px 30px
            .home-text-line
                margin-top: 50px
@media screen and (min-width: 850px)
    .about 
        height: 2750px
        #about-inner .about-column
            &:last-child
                margin-top: -1240px
            .about-item .body .body-text 
                img
                    height: 30px
                #useContext
                    height: 25px

    #projects_div .projects .project-div button
        padding: 5px 20px
        width: 150px
        font-size: .28rem
        left: calc( 50% - 95px )
    .contact
        height: 2000px
    html
        font-size: 75px
        line-height: 75px
    #home
        #homeVidSrc
            width: 100%
            height: auto
    .contact .columns .column .contact-item form .grid 
        input
            border-radius: 10px
        .form_bottom button
            line-height: .6rem
            border-radius: 10px
@media screen and (min-width: 950px)
    #projects_div .projects .project-div
        width: 33.333%
        height: ( 33.333vw / 1.778)
    .modal-project
        width: 90%
        .leave_project
            top: 35vh
            left: 1%
            transform: scale(2) translateX(25%)
            &:hover
                transform: scale(2) translateX(10%)
        .modal-inner
            height: 93vh
            top: 0
            left: 10%
            .grid
                height: 100%
                grid-template-columns: 55% 45%
                grid-template-rows: 26% auto 20%
                grid-template-areas: "title video" "desc video" "github website"
                justify-items: center
                .grid-title
                    grid-area: title
                    gap: 30px
                    .project-techs 
                        padding: 20px 80px
                        gap: 15px
                        img
                            height: 75px
                .grid-video
                    grid-area: video
                    margin-bottom: 200px
                .grid-desc
                    grid-area: desc
                    .project-desc
                        padding: 50px 100px
                .grid-github
                    grid-area: github
                    align-self: center
                    a, button
                        width: 400px
                        padding: 20px 0
                .grid-website
                    grid-area: website
                    align-self: center
                    a, button
                        width: 400px
                        padding: 20px 0

    .ReactModal__Content
        inset: 40px

@media screen and (min-width: 1200px)
    #home
        #homeVidSrc
            width: 100%
            height: auto
            left: 0
        .home-text-div
            top: 13%
            left: 10%
            .home-text-line
                gap: 70px
                a
                    margin: 30px 0
                    img
                        height: 74px
                span
                    width: 50px
                    height: 5px
                    margin-inline: 30px
    .about
        height: 1900px
        #about-inner
            margin-top: 0
            flex-direction: row
            .about-column
                gap: 250px
                width: 50%
                &:last-child
                    margin-top: 350px
                .about-item
                    max-width: 500px
                    & + .about-item
                        margin-top: -150px
                    .title
                        font-size: .25rem
                        line-height: .4rem
                    .body
                        padding: 20px 60px
                        font-size: .3rem
                        line-height: .365rem
                        .body-title img
                            top: 20px
                            height: 80px
                        .title-left
                            font-size: 0.8rem
                            margin-top: 20px
                        .body-text
                            padding-top: 20px
                            img
                                top: 4px
                                height: 24px
                            #useContext
                                height: 22px

    #editor
        bottom: 300px
        width: 500px
        right: 10vw
        margin: unset
        font-size: .2rem
        line-height: .4rem
        .title
            font-size: 0.16rem
            line-height: 0.4rem
        #editor_inside
            font-size: 0.17rem
            line-height: 0.18rem

    #projects_div
        #dont-ask
            p
                margin: 0 20px
            top: 115px
            line-height: .46rem
            .top
                font-size: .64rem
            .bottom
                font-size: .82rem
        .projects
            .project-div
                width: 25%
                height: ( 25vw / 1.778)
                .project-techs
                    gap: 10px
                    padding: 15px 20px
                    img
                        height: 30px
                button
                    width: 150px
                    padding: 5px 20px
                    font-size: 0.24rem
                    line-height: 0.4rem
                    border-radius: 7px
                    left: calc( 50% - 95px )


    .contact
        margin: auto
        width: 100%
        height: 1400px
        #old
            left: 50px
            top: 70%
            bottom: unset
            gap: 30px
            .column
                .row
                    a
                        top: 10px
        .columns
            gap: 0
            top: 0px
            width: 80%
            flex-direction: row
            margin: auto
            justify-content: center
            .column
                align-items: center
                &:last-child
                    top: 300px
                .home-text-line
                    margin-top: 20px
                    a img
                        height: 75px
                .contact-item
                    font-size: .28rem
                    line-height: .35rem
                    border-radius: 20px
                    width: 550px
                    .text
                        padding: 20px 30px
                        b
                            font-size: 0.54rem
                        img
                            top: 5px
                            height: 25px
                    form
                        padding: 25px 50px
                        .grid
                            input
                                border-radius: 5px
                            .grid-message
                                margin-top: 5px
                            .form_bottom
                                button
                                    font-size: 0.22rem
                                    line-height: 0.42rem


@media screen and (min-width: 1600px)
    html
        font-size: 100px
        line-height: 100px
    .about
        height: 2300px
        #about-inner .about-column .about-item
            max-width: 600px
            .body
                font-size: .28rem
                .body-text
                    img
                        height: 30px
                    #useContext
                        top: 6px
                        height: 27px
    #editor
        width: 600px
        right: 13vw
        #editor_inside
            font-size: .14rem
            line-height: .15rem
    #projects_div
        #dont-ask
            top: 170px
            line-height: .4rem
        .projects
            .project-div
                .project-techs
                    gap: 10px
                    padding: 20px 30px
                    img
                        height: 45px
                button
                    width: 200px
                    left: calc( 50% - 120px )
    .contact
        .columns
            .column
                top: -200px
                &:last-child
                    top: 100px
                .contact-item
                    width: 620px
                    font-size: .26rem
                    line-height: .34rem
                    .text
                        padding: 20px 30px
                        b
                            font-size: .42rem
                    form
                        padding: 25px 50px

@media screen and (min-width: 2000px)
    #home, .contact, .about, #dont-ask, #projects_div .title
        zoom: 1.1
@media screen and (min-width: 2200px)
    #home, .contact, .about, #dont-ask, #projects_div .title
        zoom: 1.2
@media screen and (min-width: 2400px)
    #home, .contact, .about, #dont-ask, #projects_div .title
        zoom: 1.3