#leave_abyss
    position: absolute
    z-index: 9999
    top: 1%
    right: 14%
    transform: rotate(90deg)
    transition: 7s all ease-in-out
    &:hover
        animation: move_from_abyss 2s ease-in-out forwards



@keyframes move_from_abyss 
    0%  
        transform: translateY(0%) rotate(90deg)
    100%  
        transform: translateY(-20%) rotate(90deg)