#leave_abyss {
  position: absolute;
  z-index: 9999;
  top: 1%;
  right: 14%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: 7s all ease-in-out;
  transition: 7s all ease-in-out;
}

#leave_abyss:hover {
  -webkit-animation: move_from_abyss 2s ease-in-out forwards;
          animation: move_from_abyss 2s ease-in-out forwards;
}

@-webkit-keyframes move_from_abyss {
  0% {
    -webkit-transform: translateY(0%) rotate(90deg);
            transform: translateY(0%) rotate(90deg);
  }
  100% {
    -webkit-transform: translateY(-20%) rotate(90deg);
            transform: translateY(-20%) rotate(90deg);
  }
}

@keyframes move_from_abyss {
  0% {
    -webkit-transform: translateY(0%) rotate(90deg);
            transform: translateY(0%) rotate(90deg);
  }
  100% {
    -webkit-transform: translateY(-20%) rotate(90deg);
            transform: translateY(-20%) rotate(90deg);
  }
}
