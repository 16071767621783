@media screen and (max-width: 305px) {
  #projects_div #dont-ask {
    top: 50px;
  }
  html {
    zoom: 0.5;
  }
}

@media screen and (min-width: 500px) {
  html {
    font-size: 50px;
    line-height: 50px;
  }
  .about {
    height: 2250px;
  }
  .about #about-inner .about-column {
    gap: 650px;
  }
  .about #about-inner .about-column:last-child {
    margin-top: -1030px;
  }
  .about #about-inner .about-column .about-item + .about-item {
    margin-top: -200px;
  }
  .about #about-inner .about-column .about-item .body .body-title img {
    height: 100px;
  }
  .about #about-inner .about-column .about-item .body .body-text img {
    height: 25px;
  }
  .about #about-inner .about-column .about-item .body .body-text #useContext {
    height: 19px;
  }
  #projects_div .projects .project-div button {
    font-size: .24rem;
  }
  #editor #editor_inside {
    font-size: .28rem;
    line-height: .28rem;
  }
  .contact {
    height: 1500px;
  }
  .contact .columns .column .contact-item form .grid .form_bottom button {
    border-radius: 10px;
    font-size: .3rem;
    line-height: .7rem;
  }
  .contact .columns .column .home-text-line {
    margin-left: 50px;
    margin-top: 20px;
  }
  .contact .columns .column .home-text-line a img {
    height: 50px;
  }
}

@media screen and (min-width: 700px) {
  #projects_div .projects .project-div button {
    padding: 5px 20px;
    width: 125px;
    font-size: .28rem;
    left: calc( 50% - 82.5px);
  }
  .contact .columns .column .home-text-line a img {
    height: 75px;
  }
  .about {
    height: 2500px;
  }
  .about #about-inner .about-column {
    gap: 750px;
  }
  .about #about-inner .about-column:last-child {
    margin-top: -1130px;
  }
  #home #homeVidSrc {
    left: 0;
    height: 100vh;
    width: auto;
  }
  html {
    font-size: 60px;
    line-height: 60px;
  }
  .contact {
    height: 1700px;
  }
  .contact .columns .column .contact-item form {
    padding: 30px 30px;
  }
  .contact .columns .column .home-text-line {
    margin-top: 50px;
  }
}

@media screen and (min-width: 850px) {
  .about {
    height: 2750px;
  }
  .about #about-inner .about-column:last-child {
    margin-top: -1240px;
  }
  .about #about-inner .about-column .about-item .body .body-text img {
    height: 30px;
  }
  .about #about-inner .about-column .about-item .body .body-text #useContext {
    height: 25px;
  }
  #projects_div .projects .project-div button {
    padding: 5px 20px;
    width: 150px;
    font-size: .28rem;
    left: calc( 50% - 95px);
  }
  .contact {
    height: 2000px;
  }
  html {
    font-size: 75px;
    line-height: 75px;
  }
  #home #homeVidSrc {
    width: 100%;
    height: auto;
  }
  .contact .columns .column .contact-item form .grid input {
    border-radius: 10px;
  }
  .contact .columns .column .contact-item form .grid .form_bottom button {
    line-height: .6rem;
    border-radius: 10px;
  }
}

@media screen and (min-width: 950px) {
  #projects_div .projects .project-div {
    width: 33.333%;
    height: 18.74747vw;
  }
  .modal-project {
    width: 90%;
  }
  .modal-project .leave_project {
    top: 35vh;
    left: 1%;
    -webkit-transform: scale(2) translateX(25%);
            transform: scale(2) translateX(25%);
  }
  .modal-project .leave_project:hover {
    -webkit-transform: scale(2) translateX(10%);
            transform: scale(2) translateX(10%);
  }
  .modal-project .modal-inner {
    height: 93vh;
    top: 0;
    left: 10%;
  }
  .modal-project .modal-inner .grid {
    height: 100%;
    -ms-grid-columns: 55% 45%;
        grid-template-columns: 55% 45%;
    -ms-grid-rows: 26% auto 20%;
        grid-template-rows: 26% auto 20%;
        grid-template-areas: "title video" "desc video" "github website";
    justify-items: center;
  }
  .modal-project .modal-inner .grid .grid-title {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: title;
    gap: 30px;
  }
  .modal-project .modal-inner .grid .grid-title .project-techs {
    padding: 20px 80px;
    gap: 15px;
  }
  .modal-project .modal-inner .grid .grid-title .project-techs img {
    height: 75px;
  }
  .modal-project .modal-inner .grid .grid-video {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    grid-area: video;
    margin-bottom: 200px;
  }
  .modal-project .modal-inner .grid .grid-desc {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: desc;
  }
  .modal-project .modal-inner .grid .grid-desc .project-desc {
    padding: 50px 100px;
  }
  .modal-project .modal-inner .grid .grid-github {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: github;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .modal-project .modal-inner .grid .grid-github a, .modal-project .modal-inner .grid .grid-github button {
    width: 400px;
    padding: 20px 0;
  }
  .modal-project .modal-inner .grid .grid-website {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: website;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .modal-project .modal-inner .grid .grid-website a, .modal-project .modal-inner .grid .grid-website button {
    width: 400px;
    padding: 20px 0;
  }
  .ReactModal__Content {
    inset: 40px;
  }
}

@media screen and (min-width: 1200px) {
  #home #homeVidSrc {
    width: 100%;
    height: auto;
    left: 0;
  }
  #home .home-text-div {
    top: 13%;
    left: 10%;
  }
  #home .home-text-div .home-text-line {
    gap: 70px;
  }
  #home .home-text-div .home-text-line a {
    margin: 30px 0;
  }
  #home .home-text-div .home-text-line a img {
    height: 74px;
  }
  #home .home-text-div .home-text-line span {
    width: 50px;
    height: 5px;
    margin-inline: 30px;
  }
  .about {
    height: 1900px;
  }
  .about #about-inner {
    margin-top: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .about #about-inner .about-column {
    gap: 250px;
    width: 50%;
  }
  .about #about-inner .about-column:last-child {
    margin-top: 350px;
  }
  .about #about-inner .about-column .about-item {
    max-width: 500px;
  }
  .about #about-inner .about-column .about-item + .about-item {
    margin-top: -150px;
  }
  .about #about-inner .about-column .about-item .title {
    font-size: .25rem;
    line-height: .4rem;
  }
  .about #about-inner .about-column .about-item .body {
    padding: 20px 60px;
    font-size: .3rem;
    line-height: .365rem;
  }
  .about #about-inner .about-column .about-item .body .body-title img {
    top: 20px;
    height: 80px;
  }
  .about #about-inner .about-column .about-item .body .title-left {
    font-size: 0.8rem;
    margin-top: 20px;
  }
  .about #about-inner .about-column .about-item .body .body-text {
    padding-top: 20px;
  }
  .about #about-inner .about-column .about-item .body .body-text img {
    top: 4px;
    height: 24px;
  }
  .about #about-inner .about-column .about-item .body .body-text #useContext {
    height: 22px;
  }
  #editor {
    bottom: 300px;
    width: 500px;
    right: 10vw;
    margin: unset;
    font-size: .2rem;
    line-height: .4rem;
  }
  #editor .title {
    font-size: 0.16rem;
    line-height: 0.4rem;
  }
  #editor #editor_inside {
    font-size: 0.17rem;
    line-height: 0.18rem;
  }
  #projects_div #dont-ask {
    top: 115px;
    line-height: .46rem;
  }
  #projects_div #dont-ask p {
    margin: 0 20px;
  }
  #projects_div #dont-ask .top {
    font-size: .64rem;
  }
  #projects_div #dont-ask .bottom {
    font-size: .82rem;
  }
  #projects_div .projects .project-div {
    width: 25%;
    height: 14.06074vw;
  }
  #projects_div .projects .project-div .project-techs {
    gap: 10px;
    padding: 15px 20px;
  }
  #projects_div .projects .project-div .project-techs img {
    height: 30px;
  }
  #projects_div .projects .project-div button {
    width: 150px;
    padding: 5px 20px;
    font-size: 0.24rem;
    line-height: 0.4rem;
    border-radius: 7px;
    left: calc( 50% - 95px);
  }
  .contact {
    margin: auto;
    width: 100%;
    height: 1400px;
  }
  .contact #old {
    left: 50px;
    top: 70%;
    bottom: unset;
    gap: 30px;
  }
  .contact #old .column .row a {
    top: 10px;
  }
  .contact .columns {
    gap: 0;
    top: 0px;
    width: 80%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .contact .columns .column {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .contact .columns .column:last-child {
    top: 300px;
  }
  .contact .columns .column .home-text-line {
    margin-top: 20px;
  }
  .contact .columns .column .home-text-line a img {
    height: 75px;
  }
  .contact .columns .column .contact-item {
    font-size: .28rem;
    line-height: .35rem;
    border-radius: 20px;
    width: 550px;
  }
  .contact .columns .column .contact-item .text {
    padding: 20px 30px;
  }
  .contact .columns .column .contact-item .text b {
    font-size: 0.54rem;
  }
  .contact .columns .column .contact-item .text img {
    top: 5px;
    height: 25px;
  }
  .contact .columns .column .contact-item form {
    padding: 25px 50px;
  }
  .contact .columns .column .contact-item form .grid input {
    border-radius: 5px;
  }
  .contact .columns .column .contact-item form .grid .grid-message {
    margin-top: 5px;
  }
  .contact .columns .column .contact-item form .grid .form_bottom button {
    font-size: 0.22rem;
    line-height: 0.42rem;
  }
}

@media screen and (min-width: 1600px) {
  html {
    font-size: 100px;
    line-height: 100px;
  }
  .about {
    height: 2300px;
  }
  .about #about-inner .about-column .about-item {
    max-width: 600px;
  }
  .about #about-inner .about-column .about-item .body {
    font-size: .28rem;
  }
  .about #about-inner .about-column .about-item .body .body-text img {
    height: 30px;
  }
  .about #about-inner .about-column .about-item .body .body-text #useContext {
    top: 6px;
    height: 27px;
  }
  #editor {
    width: 600px;
    right: 13vw;
  }
  #editor #editor_inside {
    font-size: .14rem;
    line-height: .15rem;
  }
  #projects_div #dont-ask {
    top: 170px;
    line-height: .4rem;
  }
  #projects_div .projects .project-div .project-techs {
    gap: 10px;
    padding: 20px 30px;
  }
  #projects_div .projects .project-div .project-techs img {
    height: 45px;
  }
  #projects_div .projects .project-div button {
    width: 200px;
    left: calc( 50% - 120px);
  }
  .contact .columns .column {
    top: -200px;
  }
  .contact .columns .column:last-child {
    top: 100px;
  }
  .contact .columns .column .contact-item {
    width: 620px;
    font-size: .26rem;
    line-height: .34rem;
  }
  .contact .columns .column .contact-item .text {
    padding: 20px 30px;
  }
  .contact .columns .column .contact-item .text b {
    font-size: .42rem;
  }
  .contact .columns .column .contact-item form {
    padding: 25px 50px;
  }
}

@media screen and (min-width: 2000px) {
  #home, .contact, .about, #dont-ask, #projects_div .title {
    zoom: 1.1;
  }
}

@media screen and (min-width: 2200px) {
  #home, .contact, .about, #dont-ask, #projects_div .title {
    zoom: 1.2;
  }
}

@media screen and (min-width: 2400px) {
  #home, .contact, .about, #dont-ask, #projects_div .title {
    zoom: 1.3;
  }
}
