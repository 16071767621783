$spinnerSize: 40;
svg.spinner {
    width: $spinnerSize + px;
    height: $spinnerSize + px;
    x: 0px; y: 0px;
    viewBox: 0 0 $spinnerSize $spinnerSize;

    circle {
        fill: transparent;
        stroke: #4471a1;
        stroke-width: 1;
        stroke-linecap: round;
        stroke-dasharray: (3.14 * $spinnerSize);
        transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
        animation: spinner 3s linear infinite;

    }
}


@keyframes spinner {
    0% {
        stroke-dashoffset: (0.66 * $spinnerSize);
        transform: rotate(0deg);

    } 50% {
        stroke-dashoffset: (3.14 * $spinnerSize);
        transform: rotate(540deg);

    } 100% {
        stroke-dashoffset: (0.66 * $spinnerSize);
        transform: rotate(1080deg);

    }
}