svg.spinner {
  width: 40px;
  height: 40px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 40 40;
}

svg.spinner circle {
  fill: transparent;
  stroke: #4471a1;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-dasharray: 125.6;
  -webkit-transform-origin: 20px 20px 0;
          transform-origin: 20px 20px 0;
  -webkit-animation: spinner 3s linear infinite;
          animation: spinner 3s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    stroke-dashoffset: 26.4;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: 125.6;
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }
  100% {
    stroke-dashoffset: 26.4;
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
}

@keyframes spinner {
  0% {
    stroke-dashoffset: 26.4;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: 125.6;
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }
  100% {
    stroke-dashoffset: 26.4;
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
}
