@import 'colors'
.about
    position: relative
    background: $aboutColor
    z-index: 28
    height: 1900px
    #home_bye
        user-drag: none
        user-select: none
        position: relative
        width: 100%
        z-index: 49
    #about_middle
        user-drag: none
        user-select: none
        position: absolute
        top: 600px
        left: 0
        z-index: 0
        width: 100%
        max-height: 1500px

    #about-inner
        display: flex
        flex-direction: column
        width: 85%
        margin: auto
        margin-top: 100px

        .about-column
            width: 100%
            display: flex
            flex-direction: column
            align-items: center
            gap: 500px
            &:last-child
                margin-top: -800px
            .about-item
                position: relative
                max-width: 600px
                z-index: 27
                word-wrap: break-word
                    
                .title
                    user-drag: none
                    user-select: none
                    text-align: center
                    color: #fff
                    font-size: .36rem
                    line-height: .5rem
                    font-weight: 200
                    margin-bottom: 10px
                .body
                    transition: 0.3s all ease-in-out
                    box-shadow: 0px 15px 40px 5px #00000040
                    font-size: .4rem
                    line-height: .55rem
                    border-radius: 20px
                    background: #fff
                    padding: 10px 30px
                    display: flex
                    flex-direction: column
                    align-items: center
                    max-height: 3000px
                    overflow-y: auto
                    &:hover
                        transition: 0.3s all ease-in-out
                        box-shadow: 0px 15px 40px 5px #00000088
                        
                    .body-title
                        img
                            position: relative
                            top: 10px
                            height: 60px
                            width: auto
                    .title-left
                        margin-top: 20px
                        width: 100%
                        font-size: 1.4rem
                    .body-text
                        padding-top: 0px
                        margin: 20px 0 40px 0
                        width: 100%
                        position: relative
                        b
                            line-height: .165rem
                        img
                            position: relative
                            top: 4px
                            height: 18px
                            width: auto
                        #useContext
                            height: 15px
                        ul
                            margin-top: 30px
                            margin-bottom: 50px
                            margin-left: 30px
                            display: flex
                            flex-direction: column
                            list-style: inside
                            li
                                display: list-item
                    #lighthouseVid
                        width: 100%
                        height: auto


                        
.react-rotate 
    animation: react-logo-spin infinite 20s linear
  
@keyframes react-logo-spin 
  from 
    transform: rotate(0deg)
  to 
    transform: rotate(360deg)
  