
#pseudo-body{
    position: relative;
    background: radial-gradient(circle, rgba(17,17,17,1) 0%, rgba(7,7,7,1) 34%, rgba(0,0,0,1) 100%);  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    transition: all 1.3s ease-in-out;
    height: 100vh;
    
}
#after-background{
    z-index: 1997;
    top:0;
    left:0;
    position: relative;
    opacity: 1;
    background-color: #FFF;
    width: 100%;
    cursor:grab;
    height: 100vh;
}
#pseudo-background{
    background: radial-gradient(circle, rgba(0,0,0,255) 0%, rgba(7,7,7,1) 64%, rgba(255,255,255,70) 100%);
    position: absolute;
    z-index: 998;
    opacity: 0;
    width: 100%;
    height: 100%;
    animation: breathe 12s ease-in-out infinite;
}
@keyframes breathe {
    0% {opacity: 0;}
    40% {opacity: 0;}
    70% {opacity: 0.1;}
    87% {opacity: 0.4;}
    100% {opacity: 0;}
}

@keyframes move {
    0% {left:19%;width:30px;height:30px;}
    30% {width:10px;height:10px;
        border-radius: 10%;}
    50% {left:90%;width:30px;height:30px;
        border-radius: 100%;}
    70% {width:10px;height:10px;
        border-radius: 20%;}
    100% {left:19%;width:30px;height:30px;}
}
@keyframes white {
    0% {background: black;}
    100% {background: transparent;}
}
@keyframes rotate {
    from {transform: rotate(0);}
    to {transform: rotate(360deg);}
}
@keyframes wave{
    0% {width: 65px;height: 67px;opacity:1;transform: rotate(0);}
    20% {opacity:0.4;}
    60% {opacity:0.05;}
    100% {width: 1000px;height: 1070px;opacity: 0;transform: rotate(-980deg);}
}
.secret{
    position: absolute;
    height: 72px;
    width: 70px;
    border: solid 1px #f0f0f0;
    background-color: #000;
    border-radius: 100%;
    opacity: 1;
    animation: rotate 2s infinite linear;
    z-index: 2;
}
.secret2{
    position: absolute;
    height: 72px;
    width: 70px;
    z-index: 999;
    cursor:grabbing;
}

.secret2:hover + #circle{
    animation-play-state: paused;
}
.wave{
    position: absolute;
    background-color: #000;
    height: 72px;
    width: 70px;
    border: solid 1px #f0f0f0;
    border-radius: 100%;
}
#circle{
    cursor: wait;
    z-index: 9;
    position: absolute;
    background-color: #FFF;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    animation: move 1.7s infinite 0.0s;
}
.w1{
    -webkit-backface-visibility: hidden;
-moz-backface-visibility:    hidden;
-ms-backface-visibility:     hidden;
    animation: wave 2.4s infinite 0.3s ease-out;
}
.w2{
    -webkit-backface-visibility: hidden;
-moz-backface-visibility:    hidden;
-ms-backface-visibility:     hidden;
    animation: wave 2.4s infinite 0.9s ease-out;
}
.w3{
    -webkit-backface-visibility: hidden;
-moz-backface-visibility:    hidden;
-ms-backface-visibility:     hidden;
    animation: wave 2.4s infinite 1.2s ease-out;
}

#finished{
    position: relative;
    font-size: 72px;
    top:-35vh;
    left:-20vw;
    opacity: 0;
    letter-spacing: 12px;
    transition: 3.5s ease-in-out all;
    transition-delay: 2.5s;
}

@media only screen and (max-width: 1500px) {
    #finished{font-size: 58px;padding-left: 20vw;}
}
@media only screen and (max-width: 1250px) {
    #finished{font-size: 42px;letter-spacing: 6px;padding-left: 30vw;}
}
@media only screen and (max-width: 1000px) {
    #finished{font-size: 36px;letter-spacing: 4.5px;padding-left: 35vw;}
}
@media only screen and (max-width: 750px) {
    #finished{font-size: 30px;letter-spacing: 3px;padding-left: 45vw;}
}