@import 'colors'
$vidHeight: 360px
@supports (backdrop-filter: blur(5px))
    .project-title
        backdrop-filter: blur(5px)

#projects_div
    position: relative
    background: $projectsColor
    z-index: 25
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    .spinner
        transform: scale(5)
        margin-top: 100px
    #about_bye
        width: 100%
        user-drag: none
        user-select: none
        z-index: 26
    #dont-ask
        transform: rotate(11deg)
        user-select: none
        top: 8.7vw
        display: flex
        z-index: 30
        flex-direction: column
        align-items: center
        width: 80%
        position: absolute
        line-height: .29rem
        color: #fff
        p
            margin: 0 5px
        .top
            display: flex
            flex-direction: row
            width: 90%
            align-self: flex-start
            font-size: .43rem
            font-weight: 200
            p
                color: #E73636
                font-weight: 500
                b
                    font-weight: 600

        .bottom
            display: flex
            flex-direction: row
            align-self: flex-end
            font-size: .51rem
            font-weight: 400
            p
                color: #22D234
                b
                    font-weight: 600

    .title
        user-select: none
        margin-top: 100px
        align-self: flex-start
        color: #fff
        font-weight: 200
        position: relative
        padding-left: 7%
        margin-bottom: 50px
    .projects
        margin-bottom: 150px
        z-index: 30
        position: relative
        display: flex
        height: max-content
        flex-direction: row
        flex-wrap: wrap
        width: 100%
        .project-div
            position: relative
            z-index: 31
            width: 50%
            transition: 0.15s all ease-in-out
            height: ( 50vw / 1.778)
            margin: 0
            padding: 0
            gap: 0
            
            &:hover 
                transition: 0.15s all ease-in-out
                .vid
                    transition: 0.15s all ease-in-out
                    filter: brightness(0.5)
                button
                    opacity: 1

                

            .project-techs
                transition: 0.15s all ease-in-out
                display: flex
                position: absolute
                z-index: 33
                gap: 5px
                padding: 7px 12px
                img
                    height: 20px
                    width: auto

            .project-title
                transition: 0.15s all ease-in-out
                position: absolute
                top: 30%
                font-size: 0.3rem
                line-height: 0.5rem
                padding-inline: 30px 20px
                font-weight: 200
                z-index: 33
                background: #33475677
                color: #fff
                width: max-content

            .vid
                transition: 0.15s all ease-in-out
                z-index: 32
                top: 0
                position: relative
                width: 100%
                height: auto
                opacity: 0.5
                filter: brightness(0.8)
                
            button
                opacity: 0
                position: absolute
                z-index: 35
                width: 100px
                line-height: 0.4rem
                border-radius: 5px
                color: #fff
                text-align: center
                font-weight: 200
                padding: 5px 10px
                font-size: 0.35rem
                background: $aboutColor
                box-shadow: 0px 2px 20px 10px #0005
                left: calc( 50% - 60px )
                top: 60%
                &:hover
                    box-shadow: 0px 2px 10px 5px #0003
                    transform: scale(1.2)

.modal-project
    position: relative
    background: $projectsColor
    transition: 0.5s all ease-in-out
    width: 100%
    left: 0
    z-index: 110
    .leave_project
        position: absolute
        top: -15px
        left: -15px
        transform: scale(0.8) translateX(25%)
        transition: 0.3s all ease-in-out
        &:hover
            transition: 0.3s all ease-in-out
            transform: scale(1) translateX(10%)
    .modal-inner
        position: absolute
        width: 100%
        height: 78vh
        top: 45px
        left: 0
        background: #082032
        box-shadow: inset 0px 4px 47px 27px rgba(0, 0, 0, 0.25)
        border-radius: 40px
        a
            font-size: .32rem
            line-height: .32rem
            color: #fff
            font-weight: 200
            background: $orange
            text-align: center
            border-radius: 15px
            transition: 0.2s all ease-in-out
            width: 80%
            box-shadow: 0px 4px 15px 5px #00000080
            padding: 12px 0
            &:hover
                transition: 0.2s all ease-in-out
                transform: scale(1.1)
                box-shadow: 0px 4px 25px 10px #00000040
        button
            font-size: .32rem
            line-height: .32rem
            color: #fff
            cursor: not-allowed
            font-weight: 200
            background: #aaa
            text-align: center
            padding: 12px 0
            border-radius: 15px
            width: 80%
            box-shadow: 0px 4px 15px 5px #00000080
            &:hover
                transition: 0.2s all ease-in-out
                transform: scale(1.1)
                box-shadow: 0px 4px 25px 10px #00000040

        .grid
            height: 100%
            display: grid
            grid-template-columns: 100%
            grid-template-rows: 20% 30% auto 60px 60px
            grid-template-areas: "title" "video" "desc" "github" "website"
            justify-items: center
            .grid-title
                margin-top: 15px
                grid-area: title
                justify-self: start
                display: flex
                flex-direction: column
                gap: 10px
                .project-techs
                    transition: 0.15s all ease-in-out
                    display: flex
                    z-index: 33
                    gap: 10px
                    padding: 5px 30px 0px 30px
                    img
                        height: 35px
                        width: auto
                
                .project-title
                    transition: 0.15s all ease-in-out
                    font-size: .7rem
                    width: max-content
                    line-height: 1rem
                    padding-inline: 20% 30px
                    font-weight: 200
                    z-index: 33
                    background: #33475677
                    color: #fff
            .grid-desc
                grid-area: desc
                overflow-y: overlay
                overflow-x: hidden
                .project-desc
                    color: #fff
                    padding: 10px 30px
                    width: 86%
                    font-size: .36rem
                    line-height: .44rem
                    font-weight: 200
            .grid-video
                grid-area: video
                display: flex
                border-radius: 15px
                .vid
                    margin: auto
                    width: auto
                    max-width: 95%
                    height: 100%
                    border-radius: 15px
            .grid-github
                width: 100%
                grid-area: github
                display: flex
                justify-content: center
                align-items: center
            .grid-website
                width: 100%
                display: flex
                grid-area: website
                justify-content: center
                align-items: flex-start
            








.ReactModal__Content
    inset: 0px !important

.ReactModal__Overlay 
    background: $projectsColor !important
    transform: translateX(100%)
    z-index: 105
    transition: all 0.4s ease-in-out

.ReactModal__Overlay--after-open
    transition: all 0.4s ease-in-out
    transform: translateX(0%)


.ReactModal__Overlay--before-close
    transition: all 0.4s ease-in-out
    transform: translateX(100%)
