
#oldpageInner{
  position: relative;
  z-index: 3;
  transition: 0.2s all ease-in-out;
}

#oldbuttonUp{
  position: absolute;
  top: 0px;
  left: calc(50% - 125px);
  height: 50px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

#oldbuttonDown{
  position: absolute;
  bottom: 0px;
  left: calc(50% - 125px);
  height: 50px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}




#go_back{
  bottom: 100px;
  right: 100px;
  position: absolute;
  background: #FF4C29;
  color: #fff;
  z-index: 999;
  padding: 10px 40px;
  border-radius: 10px;
  transition: 0.2s all ease-in-out;
  box-shadow: 0px 4px 25px 10px #00000040
}

@media only  screen and (max-width:700px) {
  #go_back{right: 20px;bottom: 20px;}
} 

#go_back:hover{
  transition: 0.2s all ease-in-out;
  transform: scale(1.04);
  box-shadow: 0px 4px 25px 10px #00000060;
}

#old-body{
  background-color: #fff !important;
  line-height: 24px;
  font-size: 20px;
  overflow: hidden;
}
#oldrightHalf{
  all:unset;
}

#oldbackground{
  position: fixed;
  z-index: 1;
  display: flex;
  transition: 2.7s all ease-in-out;
  top:0;
  right: -20px;
  transform-origin: top right;
  transform: scale(1);
}
#oldbackground2{
  z-index: 2;
  display: flex;
  position: fixed;
  transition: 2.7s all ease-in-out;
  top:0;
  right: -20px;
  transform-origin: top right;
  transform: scale(1.05);

}
#oldbackgroundR{
  position: fixed;
  z-index: 1;
  display: flex;
  transition: 2.7s all ease-in-out;
  bottom:-50vh;
  left: -100vw;
  transform-origin: center;
  filter: blur(1px);
  transform: scale(0) rotateZ(180deg);
}
#oldbackgroundR2{
  z-index: 2;
  display: flex;
  position: fixed;
  transition: 2.7s all ease-in-out;
  bottom:-50vh;
  left: -100vw;
  filter: blur(1px);
  transform-origin: center;
  transform: scale(0) rotateZ(180deg);

}

#oldbackgroundD{
  position: fixed;
  z-index: 1;
  display: flex;
  transition: 1.5s all ease-in-out;
  bottom:0px;
  right: -200vw;
  transform-origin: center;
  transform: scale(1.25) rotateZ(90deg);
}
#oldbackgroundD2{
  z-index: 2;
  display: flex;
  position: fixed;
  transition: 1.5s all ease-in-out;
  bottom:0px;
  right: -200vw;
  transform-origin: center;
  transform: scale(1.35) rotateZ(90deg);

}
@media only  screen and (max-width:1500px) {
  #oldbackground{transform: scale(0.8);}
  #oldbackground2{transform: scale(0.9);}
}

@media only  screen and (max-width:1250px) {
  #oldbackground{transform: scale(0.6);}
  #oldbackground2{transform: scale(0.7);}
} 
@media only  screen and (max-width:1000px) {
  #oldbackground{transform: scale(0.5) scaleY(1.5);}
  #oldbackground2{transform: scale(0.6) scaleY(1.3);}
} 
@media only  screen and (max-width:400px) {
  #oldbackground{transform: scale(0.3) scaleY(1.5);}
  #oldbackground2{transform: scale(0.3) scaleY(1.75);}
} 

#oldblackpath{
  animation: blackbg 32.2s ease-in-out infinite;
}
#oldgraypath{
  animation: graybg 32.2s ease-in-out infinite;
}

@keyframes blackbg {
  0% {d: path('M0 0 H 800 V 700 Q 791 644 700 613 T 515 455.5 T 303 270 T 102 68 T 0 0 ');}
  10% {d: path('M0 0 H 800 V 700 Q 782 640 700 613 T 502 453.5 T 301 250 T 106 69 T 0 0 ');}
  20% {d: path('M0 0 H 800 V 700 Q 804 641 700 613 T 501 425.5 T 297 251 T 120 77 T 0 0 ');}
  30% {d: path('M0 0 H 800 V 700 Q 783 665 700 613 T 504 444.5 T 305 267 T 112 102 T 0 0 ');}
  40% {d: path('M0 0 H 800 V 700 Q 785 653 700 613 T 517 449.5 T 303 264 T 102 85 T 0 0 ');}
  50% {d: path('M0 0 H 800 V 700 Q 819 655 700 613 T 483 418.5 T 302 244 T 120 73 T 0 0 ');}
  60% {d: path('M0 0 H 800 V 700 Q 801 655 700 613 T 516 417.5 T 285 278 T 92 68 T 0 0 ');}
  70% {d: path('M0 0 H 800 V 700 Q 814 650 700 613 T 516 453.5 T 300 281 T 89 100 T 0 0 ');}
  80% {d: path('M0 0 H 800 V 700 Q 795 669 700 613 T 507 447.5 T 280 273 T 106 68 T 0 0 ');}
  90% {d: path('M0 0 H 800 V 700 Q 787 659 700 613 T 488 434.5 T 304 273 T 80 68 T 0 0 ');}
  100% {d: path('M0 0 H 800 V 700 Q 791 644 700 613 T 515 455.5 T 303 270 T 102 68 T 0 0 ');}
}
@keyframes graybg {
  0% {d: path('M0 0 H 800 V 700 Q 781 660 700 613 T 501 457.5 T 298 255 T 101 72 T 0 0 ');}
  10% {d: path('M0 0 H 800 V 700 Q 809 657 700 613 T 488 439.5 T 311 258 T 116 84 T 0 0 ');}
  20% {d: path('M0 0 H 800 V 700 Q 793 672 700 613 T 503 431.5 T 284 269 T 118 70 T 0 0 ');}
  30% {d: path('M0 0 H 800 V 700 Q 816 664 700 613 T 500 443.5 T 294 256 T 95 74 T 0 0 ');}
  40% {d: path('M0 0 H 800 V 700 Q 789 643 700 613 T 485 444.5 T 316 268 T 113 68 T 0 0 ');}
  50% {d: path('M0 0 H 800 V 700 Q 815 665 700 613 T 496 453.5 T 315 273 T 80 84 T 0 0 ');}
  60% {d: path('M0 0 H 800 V 700 Q 813 646 700 613 T 486 422.5 T 311 254 T 84 104 T 0 0 ');}
  70% {d: path('M0 0 H 800 V 700 Q 802 663 700 613 T 487 442.5 T 300 249 T 90 87 T 0 0 ');}
  80% {d: path('M0 0 H 800 V 700 Q 813 647 700 613 T 514 424.5 T 289 245 T 88 92 T 0 0 ');}
  90% {d: path('M0 0 H 800 V 700 Q 802 677 700 613 T 512 448.5 T 305 253 T 95 82 T 0 0 ');}
  100% {d: path('M0 0 H 800 V 700 Q 781 660 700 613 T 501 457.5 T 298 255 T 101 72 T 0 0 ');}
}